import React from "react";

import PhoneGroupImage from "../../assets/mockup.png";

const Head = () => {
  return (
    <div className=" flex flex-col items-center justify-between pt-10 gap-4 bg-secondary md:h-[93vh]">
      <h1 className="text-3xl md:text-7xl font-extrabold text-center bg-gradient-to-tr from-gray-200 to-slate-400 text-transparent bg-clip-text">
        Toplu Taşıma Araçları <br />
        <span className="flex">
          <h1 className="font-black text-primary select-none">Route</h1>
          <h1 className="font-medium text-primary select-none">Wise </h1> &nbsp;
          ile Güvende!
        </span>
      </h1>
      <p className="font-semibold md:w-2/5 px-4 text-center opacity-80 text-white">
        Öğrencilerinizi, yolcularınızı ve araçlarınızı yüksek teknoloji
        altyapımız ile güvende tutun.
      </p>
      <div className="w-2/5 flex items-center justify-center p-6">
        <h1 className="font-extrabold select-none text-4xl bg-gradient-to-bl from-primary to-slate-300 text-transparent bg-clip-text">
          Çok Yakında Marketlerde!
        </h1>
      </div>
      <div className="">
        <img
          src={PhoneGroupImage}
          alt="Phone Group"
          className="w-11/12 mx-auto"
        />
      </div>
    </div>
  );
};

export default Head;
