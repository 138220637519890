import React from "react";
import { WhiteLogo } from "../assets";
import { InstagramIcon, LinkedinIcon } from "../assets/Icon";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="md:h-96 h-80 flex flex-col text-white py-8 justify-between bg-secondary border-t-[0.5px] border-slate-400">
      <div className="flex flex-col md:flex-row container mx-auto justify-between px-10 gap-2">
        <div>
          <img src={WhiteLogo} alt="WhiteLogo" className="md:w-64 w-52" />
        </div>
        <div className="md:w-1/3 flex justify-between">
          <div className="flex flex-col">
            <a
              href="https://linkedin.com/company/routewise"
              target="_blank"
              rel="noreferrer"
              className="font-bold md:text-lg text-sm bg-gradient-to-tr from-gray-200 to-slate-400 text-transparent bg-clip-text"
            >
              Hakkımızda
            </a>

            <a
              href="https://linkedin.com/company/routewise"
              target="_blank"
              rel="noreferrer"
              className="font-bold md:text-lg text-sm bg-gradient-to-tr from-gray-200 to-slate-400 text-transparent bg-clip-text"
            >
              KVKK
            </a>
            <a
              href="https://linkedin.com/company/routewise"
              target="_blank"
              rel="noreferrer"
              className="font-bold md:text-lg text-sm bg-gradient-to-tr from-gray-200 to-slate-400 text-transparent bg-clip-text"
            >
              Kariyer
            </a>
          </div>
          <div className="flex flex-col items-center gap-2">
            <h1 className="w-full text-center border-b-2 bg-gradient-to-tr from-gray-200 to-slate-400 text-transparent bg-clip-text font-extrabold md:text-lg text-sm border-b-slate-400">
              Sosyal
            </h1>
            <div className="flex gap-4">
              <a
                href="https://www.instagram.com/routewiseapp/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon
                  width={window.innerWidth < 768 ? 40 : 60}
                  height={window.innerWidth < 768 ? 40 : 60}
                  className="fill-slate-300 transition-colors duration-300 hover:fill-primary"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/routewise"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinIcon
                  width={window.innerWidth < 768 ? 40 : 60}
                  height={window.innerWidth < 768 ? 40 : 60}
                  className="fill-slate-300 transition-colors duration-300 hover:fill-primary"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between text-xs md:text-sm container mx-auto border-t-[0.5px] border-slate-400 p-8">
        <h1 className="font-semibold text-white/50">
          All Rights Reserved ©RouteWise {currentYear}
        </h1>
        <div className="flex gap-8">
          <button className="text-white font-semibold transition-colors duration-300 hover:text-primary">
            Kullanım Sözleşmesi
          </button>
          <button className="text-white font-semibold transition-colors duration-300 hover:text-primary">
            Gizlilik Sözleşmesi
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
