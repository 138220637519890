import React from "react";

const Features = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center">
        <h1 className="text-3xl md:text-7xl font-extrabold text-center bg-gradient-to-tr from-gray-200 to-slate-400 text-transparent bg-clip-text py-2">
          Teknolojilerimizi Keşfet!
        </h1>
        <p className="md:w-1/2 w-full px-10 font-semibold text-white/60 text-center">
          Teknolojik altyapımız, yolcu ve şoförlerimizin en güvenli yolculukları
          yapabilmeleri için tasarlandı!
        </p>
      </div>
      <div className="grid mx-auto md:grid-cols-3 gap-4 md:grid-rows-3 py-4 w-11/12 md:w-full">
        <div className="col-span-2 md:col-span-1 row-span-2 p-1 rounded-xl shadow-md bg-gradient-to-tl from-[#2D2D2D] to-slate-200/40">
          <div className="w-full h-full bg-secondary rounded-lg p-10 gap-4 flex flex-col">
            <h1 className="md:text-6xl text-4xl font-extrabold text-primary">
              DriverMS
            </h1>
            <p className="text-white/60 md:text-lg font-semibold">
              Sürüş anında şoförün tüm dikkatini yola vermesi için tasarlandı.{" "}
              <u className="bg-gradient-to-tr from-primary to-white text-transparent bg-clip-text">
                RouteWise, sürücüyü tüm sürüş boyunca izler ve sürüş
                performansını analiz eder. Sürücünün kemer, telefon, sigara,
                uyku, yorgunluk, dikkat dağınıklığı gibi durumlarını anında
                tespit eder ve uyarı verir.
              </u>{" "}
              Tüm bu uyarılar anlık olarak merkeze iletilir ve sürüş puanlarına
              etki eder!
            </p>
          </div>
        </div>
        <div className="col-span-2 p-1 rounded-xl shadow-md bg-gradient-to-tr from-[#2D2D2D] to-slate-200/40">
          <div className="w-full h-full bg-secondary rounded-lg p-10 flex flex-col gap-4">
            <h1 className="md:text-6xl text-4xl font-extrabold text-primary">
              Filo Yönetimi
            </h1>

            {/* Filo Yönetimi Çözümleri */}
            <p className="text-white/60 md:text-lg font-semibold">
              Filo yönetimi çözümleri ile araçlarınızı ve sürücülerinizi anlık
              olarak takip edin.{" "}
              <u className="bg-gradient-to-tr from-primary to-white text-transparent bg-clip-text">
                RouteWise, araçlarınızın konumunu, hızını, yakıt tüketimini,
                bakım süreçlerini ve sürücülerinizi anlık olarak takip eder. Tüm
                bu verileri merkeze ileterek filo yöneticilerine anlık raporlar
                sunar.
              </u>{" "}
            </p>
          </div>
        </div>
        <div className="col-span-2  p-1 rounded-xl shadow-md bg-gradient-to-br from-[#2D2D2D] to-slate-200/40">
          <div className="w-full h-full bg-secondary rounded-lg p-10 flex flex-col gap-4">
            <h1 className="md:text-6xl text-4xl font-extrabold text-primary">
              RoadMS
            </h1>
            <p className="text-white/60 md:text-lg font-semibold">
              Yolculuk esnasında aracın yol durumunu ve sürüş performansını
              analiz eder.{" "}
              <u className="bg-gradient-to-tr from-primary to-white text-transparent bg-clip-text">
                RouteWise, kamera ile aracın yoldaki hareketlerini inceler. Tüm
                bu incelemeler araç hızı, şerit takibi, araç mesafesi, ani fren,
                ani hızlanma, ani manevralar gibi durumları anında tespit eder
                ve aksiyonlar alır.
              </u>{" "}
              Tüm bu uyarılar anlık olarak merkeze iletilir ve sürüş puanlarına
              etki eder!
            </p>
          </div>
        </div>
        <div className="col-span-2 row-span-1 p-1 rounded-xl shadow-md bg-gradient-to-tr from-[#2D2D2D] to-slate-200/40">
          <div className="w-full h-full bg-secondary rounded-lg p-10 flex flex-col gap-4">
            <h1 className="md:text-6xl text-4xl font-medium text-primary">
              <strong className="font-black">Car</strong>Kit
            </h1>
            <p className="text-white/60 md:text-lg font-semibold">
              Tüm araçlara entegre, kurulumu kolay ve düşük masraflı!{" "}
              <u className="bg-gradient-to-tr from-primary to-white text-transparent bg-clip-text">
                CarKit, araç içi sensörlere bağlantılı olarak çalışır. Aynı
                zamanda kendi üzerindeki konum sensörleri ile aracın konumunu ve
                hızını sürekli olarak takip eder.
              </u>{" "}
              Tüm bu incelemeler diğer sistemlerle ortak çalışarak anlık olarak
              merkeze iletilir ve sürüş puanlarına etki eder!
            </p>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 row-span-1 p-1 rounded-xl shadow-md bg-primary flex flex-col px-10 justify-center items-center md:items-start">
          <h1 className="text-secondary hidden md:flex font-bold md:text-3xl text-xl">
            Daha Fazlasını <br /> Keşfet!
          </h1>
          <h1 className="text-secondary flex md:hidden font-bold md:text-3xl text-xl">
            Daha Fazlasını Keşfet!
          </h1>
          <a
            href="mailto:hakan@routewise.tech?subject=RouteWise Teknolojilerini Keşfet&body=Merhaba, RouteWise ürünleri hakkında daha fazla bilgi almak istiyorum."
            className="bg-white text-center text-primary font-bold px-4 py-2 rounded-lg mt-4 w-1/2 transition-all duration-300 hover:shadow-2xl"
          >
            Keşfet
          </a>
        </div>
      </div>
    </div>
  );
};

export default Features;
