import React from "react";
import { BlueLogo } from "../assets";

const Header = () => {
  const buttonData = [
    {
      name: "Anasayfa",
      link: "/",
    },
    {
      name: "Hakkımızda",
      link: "/about",
    },
    {
      name: "Teknolojiler",
      link: "/technologies",
    },
    {
      name: "İletişim",
      link: "/contact",
    },
  ];
  return (
    <header className="h-16 flex items-center justify-between md:px-40 shadow-md bg-secondary">
      <div className="md:w-1/3 w-1/2 mx-auto">
        <img src={BlueLogo} alt="logo" className="md:h-10" />
      </div>
      <div className="w-2/3 md:flex items-center justify-center hidden">
        <div className="w-2/3 flex justify-around">
          {buttonData.map((item, index) => (
            <button
              key={index}
              className="transition-all duration-200 hover:text-primary hover:scale-105 font-semibold text-sm text-white"
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
      <div className="w-1/12 md:flex hidden items-center justify-center">
        <button className="flex peer justify-center items-center border-2 border-primary hover:text-secondary text-white rounded-lg w-full py-2 text-sm font-semibold transition-colors duration-300 hover:bg-primary hover:border-primary">
          Giriş Yap
          {/* <ArrowIcon
            className={"peer-hover:fill-primary transition-colors duration-300"}
          /> */}
        </button>
      </div>
    </header>
  );
};

export default Header;
