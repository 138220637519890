import React from "react";

import RightImage from "../../assets/howItWorks.png";
import {
  AddUserIcon,
  DownloadIcon,
  LocationIcon,
  SchoolBusIcon,
} from "../../assets/Icon";

const HowItWorks = () => {
  const howItWorkData = [
    {
      title: "Uygulamayı İndir!",
      icon: DownloadIcon,
    },
    {
      title: "Servise Kayıt Ol!",
      icon: SchoolBusIcon,
    },
    {
      title: "Öğrencilerini Ekle!",
      icon: AddUserIcon,
    },
    {
      title: "Takip Etmeye Başla!",
      icon: LocationIcon,
    },
  ];
  return (
    <div className="bg-secondary">
      <div className="container mx-auto py-10">
        <div className="flex flex-col items-center text-center">
          <h1 className="text-4xl md:text-7xl font-extrabold text-center bg-gradient-to-tr from-gray-200 to-slate-400 text-transparent bg-clip-text py-2">
            Nasıl Çalışır?
          </h1>
          <p className="text-white opacity-60 w-full md:w-1/3">
            Yolculuk deneyimlerinizi daha güvenli ve daha verimli hale
            getirmenin sadeliğini keşfedin.
          </p>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-between">
          <div className="md:w-1/2 md:flex flex-col items-center justify-center md:gap-10 hidden">
            {howItWorkData.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-center gap-5 my-2 transition-all duration-300 hover:shadow-primary shadow-inner text-white border-b-[1px] border-primary w-2/3 md:p-5 rounded-3xl`}
              >
                <item.icon className="fill-white" />
                <p className="font-semibold text-xl">{item.title}</p>
              </div>
            ))}
          </div>
          <div className="md:w-1/2 flex items-center justify-center">
            <div className="md:w-2/3 w-5/6 bg-primary flex flex-col items-center pt-10 gap-10 my-10 rounded-3xl shadow-2xl">
              <p className="md:w-2/3 w-3/4 text-center text-secondary font-semibold text-sm">
                Kontrolü elinize alın ve yolculuklarınızı daha güvenli hale
                getirin. Güvenin doyumsuz hissine kapılmak için{" "}
                <u>hemen indirin</u> ve <u>kayıt olun</u>!
              </p>
              <img
                src={RightImage}
                alt="Right Image"
                className="md:w-3/5 w-3/4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
