import React from "react";

const Newsletter = () => {
  return (
    <div className="md:container w-11/12  mx-auto bg-primary gap-4 rounded-2xl md:px-20 md:py-10 flex py-4 flex-col md:flex-row justify-between md:items-center mt-4 mb-10">
      <div className="flex flex-col md:gap-2 px-4">
        <h1 className="text-secondary font-bold md:text-3xl text-xl">
          Bültene Abone Olun!
        </h1>
        <p className="md:text-sm text-xs flex md:hidden font-semibold text-secondary/60">
          Yeni güncellemeler, kampanya ve indirimlerden haberdar olmak için{" "}
          e-bültenimize abone olun.
        </p>
        <p className="md:text-sm hidden md:flex text-xs font-semibold text-secondary/60">
          Yeni güncellemeler, kampanya ve indirimlerden haberdar olmak için{" "}
          <br />
          e-bültenimize abone olun.
        </p>
      </div>
      <div className="md:w-1/2 md:flex px-4">
        <input
          type="text"
          placeholder="E-posta Adresiniz"
          className="border-2 h-12 w-3/5 md:w-2/3 border-secondary rounded-lg text-sm md:text-base px-4 py-2 outline-none bg-transparent text-white font-semibold placeholder:text-white transition-all duration-300 hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent"
        />
        <button className="bg-secondary h-12 text-white font-bold px-6 py-2 rounded-lg ml-2 transition-all duration-300 hover:shadow-2xl text-sm md:text-md">
          Abone Ol
        </button>
      </div>
    </div>
  );
};

export default Newsletter;
