export const ArrowIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="right-arrow"
    >
      <path d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"></path>
    </svg>
  );
};

export const DownloadIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      id="download"
    >
      <path
        d="M18,9h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1,0.4,1,1v7c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1h2
	c0.6,0,1-0.4,1-1S8.6,9,8,9H6c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3v-7C21,10.3,19.7,9,18,9z M8.3,14.7l3,3
	c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l3-3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L13,14.6V3c0-0.6-0.4-1-1-1
	s-1,0.4-1,1v11.6l-1.3-1.3c-0.4-0.4-1-0.4-1.4,0C7.9,13.7,7.9,14.3,8.3,14.7z"
      ></path>
    </svg>
  );
};

export const SchoolBusIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 451.44"
      id="bus"
    >
      <g data-name="Layer 2">
        <path d="M398.83 401.28H113.17a32.88 32.88 0 01-32.84-32.85V63a63 63 0 0163-63H368.69a63 63 0 0163 63V368.43A32.88 32.88 0 01398.83 401.28zM143.31 20a43 43 0 00-43 43V368.43a12.86 12.86 0 0012.84 12.85H398.83a12.86 12.86 0 0012.84-12.85V63a43 43 0 00-43-43zM50.16 240.74H10a10 10 0 01-10-10v-90.3a10 10 0 0110-10H50.16a10 10 0 0110 10v90.3A10 10 0 0150.16 240.74zM20 220.74H40.16v-70.3H20z"></path>
        <path d="M153.16 451.44H127.9A27.52 27.52 0 01100.41 424V391.28a10 10 0 0110-10h60.24a10 10 0 0110 10V424A27.52 27.52 0 01153.16 451.44zm-32.75-50.16V424a7.5 7.5 0 007.49 7.49h25.26a7.5 7.5 0 007.49-7.49V401.28zM384.1 451.44H358.84A27.52 27.52 0 01331.35 424V391.28a10 10 0 0110-10h60.24a10 10 0 0110 10V424A27.52 27.52 0 01384.1 451.44zm-32.75-50.16V424a7.5 7.5 0 007.49 7.49H384.1a7.5 7.5 0 007.49-7.49V401.28zM30.08 150.44a10 10 0 01-10-10V90.27a10 10 0 0110-10H90.33a10 10 0 010 20H40.08v40.17A10 10 0 0130.08 150.44zM502 240.74H461.84a10 10 0 01-10-10v-90.3a10 10 0 0110-10H502a10 10 0 0110 10v90.3A10 10 0 01502 240.74zm-30.16-20H492v-70.3H471.84z"></path>
        <path d="M481.92 150.44a10 10 0 01-10-10V100.27H421.67a10 10 0 010-20h60.25a10 10 0 0110 10v50.17A10 10 0 01481.92 150.44zM421.67 351.11H90.33a10 10 0 110-20H421.67a10 10 0 010 20zM381.51 220.67h-251a10 10 0 01-10-10V90.27a10 10 0 0110-10h251a10 10 0 0110 10v120.4A10 10 0 01381.51 220.67zm-241-20h231V100.27h-231zM336.33 60.13H175.67a10 10 0 010-20H336.33a10 10 0 010 20zM155.59 311a35.08 35.08 0 1135.1-35.08A35.13 35.13 0 01155.59 311zm0-50.16a15.08 15.08 0 1015.1 15.08A15.1 15.1 0 00155.59 260.81zM356.41 311a35.08 35.08 0 1135.1-35.08A35.12 35.12 0 01356.41 311zm0-50.16a15.08 15.08 0 1015.1 15.08A15.1 15.1 0 00356.41 260.81z"></path>
        <path d="M291.14,351.11H220.86a10,10,0,0,1-10-10v-25.2a45,45,0,0,1,45-45h.28a45,45,0,0,1,45,45v25.2A10,10,0,0,1,291.14,351.11Zm-60.28-20h50.28v-15.2a25,25,0,0,0-25-25h-.28a25,25,0,0,0-25,25Z"></path>
      </g>
    </svg>
  );
};

export const AddUserIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="add-user"
    >
      <g data-name="Layer 2">
        <path
          d="M21 6h-1V5a1 1 0 0 0-2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0V8h1a1 1 0 0 0 0-2zm-11 5a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm0 8a7 7 0 0 0-7 7 1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 7 7 0 0 0-7-7z"
          data-name="person-add"
        ></path>
      </g>
    </svg>
  );
};

export const LocationIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width={50}
      height={50}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="location"
    >
      <path d="M12,10.8a2,2,0,1,0-2-2A2,2,0,0,0,12,10.8Zm-.71,6.91a1,1,0,0,0,1.42,0l4.09-4.1a6.79,6.79,0,1,0-9.6,0ZM7.23,8.34A4.81,4.81,0,0,1,9.36,4.79a4.81,4.81,0,0,1,5.28,0,4.82,4.82,0,0,1,.75,7.41L12,15.59,8.61,12.2A4.77,4.77,0,0,1,7.23,8.34ZM19,20H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"></path>
    </svg>
  );
};

export const InstagramIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      id="instagram"
    >
      <path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"></path>
    </svg>
  );
};

export const LinkedinIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      id="linkedin"
    >
      <path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"></path>
    </svg>
  );
};
