import React, { useEffect } from "react";
import { Footer, Header } from "./components";
import { Features, Head, HowItWorks, Newsletter } from "./components/sections";

// import ReactGA from "react-ga";

// ReactGA.initialize("G-5DB0FJEZZP");

const App = () => {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  return (
    <>
      <Header />
      <div className="">
        <Head />
        <HowItWorks />
        <Features />
        <Newsletter />
      </div>
      <Footer />
    </>
  );
};

export default App;
